//created 20250122
export const D4_UNIQUE_AFFIXES = [
    "A Pestilent Swarm spawns from you every 4 seconds, dealing X_0 Poison damage per hit. Your Pestilent Swarms now orbit around you and generate 3 Vigor per hit.",
    "According to your secondary Spirit Hall choice, your Skills are all additionally Jaguar, Eagle, Gorilla, or Centipede Skills.Your Skills deal X_0%[x] increased damage per Spirit type they have.",
    "After casting Double Swing 4 times, your next Double Swing will hit 2 additional times, each dealing X_0%[x] increased damage and Stunning for 1 second.",
    "After spending 100 Fury within 3 seconds, your next cast of Hammer of the Ancients, Upheaval, or Death Blow within 5 seconds is a guaranteed Critical Strike and deals X_0%[x] bonus Critical Strike Damage.",
    "After using Teleport, Close enemies are Pulled to you and Stunned for X_0 seconds, but Teleport's Cooldown is increased by 20%[x].",
    "After using a combat dismount skill, you gain 40% movement speed bonus for 10 seconds.",
    "After using a combat dismount skill, you gain a shield equal to 20% of your Maximum Life.",
    "After you spend 275 of your Primary Resource, your next Core Skill is guaranteed to Overpower. Your Critical Strikes that Overpower deal X_0%[x] increased damage.",
    "Attacks against Frozen targets increase your critical strike chance by X_0%. This bonus resets upon getting a critical strike.",
    "Ball Lightning orbits you creating a static field that damages all enemies within for X_0%[x] of Ball Lightning's damage per active ball. You are  Unhindered as long as the field is active.",
    "Ball Lightning splashes on impact for X_0%[x] increased damage. It Stuns enemies for 1 second if it reaches its maximum range.Super Ball Lightnings are larger, deal  125%[x] increased damage, have a higher Lucky Hit Chance, and their Stun duration is increased to 3 seconds.",
    "Basic Skills deal X_0%[x] increased damage but additionally cost 25 Primary Resource.",
    "Bleeding enemy you kill explodes, dealing 10% of their max life to nearby enemies.",
    "Blight also shoots 4 smaller projectiles that pierce enemies and deal X_0 Shadow damage over 3 seconds.",
    "Blood Surge casts a mini nova on your Minions, dealing X_0 damage. Damage is increased by 10% per target drained by the initial cast, up to 50%.",
    "Blood Wave is additionally a Bone Skill. It spawns a Bone Prison at its end point and increases your Blood Skill damage by X_0%[x] for 8 seconds when Cast.Bone Spear is additionally a Blood Skill. It drains 10% Maximum Life each Cast after hitting an enemy to consume a surrounding Corpse and launch a new Bone Spear from it.",
    "Blood Wave now forms a wave on each side of you. Both waves converge at your feet, Pulling In all surrounding enemies and exploding for X_0 damage.Each wave hit causes enemies to take X_1% more damage from your Blood Waves, up to X_2%.",
    "Bone Prison traps a larger area and fires X_0 Bone Splinters at enemies trapped within. Increase your Maximum Essence by 2 for 10 seconds each time these Bone Splinters hit an enemy.",
    "Bone Spear deals X_0%[x] more Critical Strike Damage and forms echoes that burst for X_1 Physical damage, increased by 5%[x] for every 25% of your total Critical Strike Damage bonus.Current Bonus: 0%[x]",
    "Casting Blood Surge consumes nearby Corpses to cause mini novas, dealing X_0 damage. This damage is increased by 10%[x] for each enemy drained by your initial cast, up to 50%[x], as well as by 20%[x] for each Corpse consumed.",
    "Casting Boulder while Hurricane is active causes the Boulder to rotate around you instead. Boulder's damage is increased by X_0%[x] for each one currently rotating.You may have up to 10 Boulders active at a time.",
    "Casting Dark Shroud makes you Immune for 2 seconds, but your Evade Cooldown is increased by X_0 seconds.Evading while Dark Shroud is active leaves behind an explosion that deals X_1 Shadow damage and Pulls In enemies.",
    "Casting Evade consumes 1 stack of Resolve to increase your damage dealt by X_0%[x] for 5 seconds.Gain Armored Hide's Passive Effect.",
    "Casting Familiar now Summons all three elemental variants at once.Familiar's duration is increased by X_0%[x] and its Cooldown is reduced by 2 seconds, but its Maximum Charges are reduced by 1.",
    "Casting Fire Bolt through your Firewall causes it to split into 3 bolts, each dealing X_0%[x] increased damage.",
    "Casting Smoke Grenade increases your Critical Strike Damage by 45%[x] for X_0 seconds and leaves behind a cloud of shadows. While within the cloud, you gain Stealth once per second.",
    "Casting Steel Grasp reduces Iron Maelstrom's Cooldown by X_0 seconds. Enemies damaged by Iron Maelstrom deal X_1% less damage for 6 seconds.",
    "Casting Wrath of the Berserker causes you to explode, dealing X_0 Fire damage. While Berserking, your direct damage dealt is converted into Fire. You deal X_1%[x] increased Fire damage.",
    "Casting a Core Skill additionally fires 2 instances of Fire Bolt, Frost Bolt, or Spark, matching the elements of your last 2 non-Core Skill casts. These projectiles deal X_0%[x] increased damage.",
    "Casting a Core Skill has a X_0% chance to throw Stun Grenades that deal X_1 Physical damage and Stun enemies for 1 second.Your Stun Grenades gain 5% Lucky Hit Chance.",
    "Casting a Defensive Skill deals X_0 damage to Nearby Poisoned enemies, increased by 50%[x] for every 100 Willpower you have.Current Bonus: 0%[x]",
    "Casting a Marksman or Cutthroat Skill has a X_0% chance to Summon a Shadow Clone that mimics that Skill.",
    "Casting a Pyromancy, Shock, or Frost Skill increases your damage by X_0%[x] for 8 seconds, stacking once per element. Casting again refreshes all bonuses if the previous skill was a different Element.",
    "Casting a Skill has a X_0% chance to trigger a Non-Mobility, Non-Ultimate Skill that is currently on Cooldown. Can only occur once every 8 seconds.",
    "Casting a Skill while at full Life fires a Divine Barrage dealing X_0 damage.",
    "Casting a Subterfuge Skill leaves behind a Decoy Trap that continuously Taunts and lures enemies. After 2 seconds, it explodes, dealing X_0 Shadow damage. This effect is treated as a Trap Skill and can only spawn one Decoy Trap every 6 seconds.",
    "Casting an Ultimate Skill grants or amplifies its matching Primary Spirit Hall Bonus with X_0%[+] potency for 15 seconds.Your Ultimate Skills each gain an additional Skill Type: The Seeker is a Focus Skill. The Hunter is a Mobility Skill. The Devourer is a Potency Skill. The Protector is a Defensive Skill.",
    "Casts of Landslide leave behind Tectonic Spikes that deal X_0 Physical damage over 2 seconds.Spawning a Landslide pillar within Tectonic Spikes has a X_1% chance to spawn two instead.",
    "Cataclysm's lightning strikes now prioritize enemies.While Cataclysm is active, you gain unlimited Spirit and deal X_0%[x] increased damage.",
    "Chain Lightning alternates between orbiting you and seeking up to 3 enemies. When it returns, it drains 6 Mana from you for each active Chain Lightning. After draining 66 total Mana, the bolt explodes for X_0 Lightning Damage.                       Chain Lightning expires if you don't have enough Mana for it to drain.",
    "Claw is now also a Storm Skill and also casts Storm Strike at X_0% normal damage.",
    "Corpse Explosion consumes up to 4 additional Corpses around the initial Corpse, gaining X_0%[x] damage and X_1% Size per additional Corpse consumed.",
    "Damaging enemies with a Non-Basic Skill cast marks them for 3 seconds. When a Basic Skill first hits a marked enemy, the Basic Skill's damage is echoed to all marked enemies, dealing X_0%[x] increased damage.",
    "Death Blow creates a shockwave, dealing X_0% of its Base damage to enemies. Enemies who die to this effect also reset Death Blow’s Cooldown.",
    "Death Trap deals X_0%[x] increased damage and will rearm itself once after activating.",
    "Each enemy you Evade through reduces your active Ultimate Cooldown by X_0 seconds, up to 10 seconds.",
    "Each time you use a Skill with a Resource Cost, gain X_0%[x] increased damage and Resource Cost is increased by 30%[+] for 4 seconds, stacking up to 5 times.",
    "Effects that Heal you beyond 100% Life grant you a Barrier up to X_0% of your Maximum Life that lasts for 8 seconds. You may now drink your Healing Potion while at full Life.",
    "Enemies that die while Frozen have a X_0% chance to unleash a Frost Nova.",
    "Every 10 seconds, gain a barrier absorbing up to X_0 damage for 5 seconds.",
    "Every 2 seconds, you randomly mark a Nearby enemy. Marked enemies are Vulnerable, and your attacks against them are guaranteed to Critically Strike and Overpower.Hitting a marked enemy 10 times clears the mark from them.",
    "Every 30 seconds you spend in an animal form, your next Core Skill is guaranteed to Overpower and Critically Strike, and deals X_0%[x] increased damage.Casting a Shapeshifting Skill reduces this timer by 1 second, or 2 seconds if it changes you to a new animal form.",
    "Every 3rd cast of Fireball launches 2 additional projectiles and deals X_0%[x] increased damage.",
    "Every 6th attack from each Minion is empowered, exploding for X_0 Physical damage.",
    "Every time you deal Thorns damage, you gain X_0 Vigor.Gain Toxic Skin's Passive Effect.",
    "Fireball now bounces as it travels, exploding each time it hits the ground, but its explosion deals X_0% of normal damage.",
    "Gain the effect of the Teleport Enchantment for free. When you Evade using Teleport Enchantment you are taken to a random location.",
    "Hammer of the Ancients also forms a seismic line that deals its damage and Slows enemies hit by X_0% for 4 seconds. Earthquakes it passes through explode for their total damage and are consumed.",
    "Hits of Barrage have a X_0% chance to deal triple damage and Knock Down enemies.",
    "Hits with this weapon increase your Attack Speed by X_0%[+] for 4 seconds, up to X_1%[+].",
    "If a Core Skill hits 4 or more enemies, X_0% of the Resource cost is refunded.",
    "If you have at least 2 Blue Pacts, double in size.",
    "If you haven't dealt damage in the last 2 seconds, gain Stealth and 40%[+] Movement Speed.",
    "Increase Frenzy's maximum stacks by 2. While at maximum stacks, you deal  X_0%[x] increased damage and your other Skills gain X_1%[+] increased Attack Speed.",
    "Instead of detonating immediately, Corpse Explosion now Summons a Volatile Skeleton that charges at a random enemy and explodes. Corpse Explosion deals X_0%[x] increased damage.",
    "Killing an enemy with Shred grants Stealth for 2 seconds. Breaking Stealth with an attack grants guaranteed Critical Strikes for X_0 seconds.",
    "Lightning Storm gains 1 additional strike each times it grows.Lightning Storm Critical Strikes cause lightning to strike twice, dealing X_0%[x] increased damage.",
    "Lucky Hit: Critical Strikes with your Fury skills have up to a X_0% chance to extend the duration of Berserking by 1 second.",
    "Lucky Hit: Critical strikes with your Fury skills have up to a X_0% chance to extend the duration of Berserking by 1 second.",
    "Lucky Hit: Critically Striking an enemy has up to a 100% chance to Fear and Slow them by X_0% for 4 seconds.",
    "Lucky Hit: Inflicting Bleeding on an enemy has up to a X_0% chance to reduce the Cooldowns of your Skills by 1.0 second.",
    "Lucky Hit: Storm Skills have up to a X_0% chance to grant 4 Spirit.Your base Storm Skills are now also Werewolf Skills.",
    "Lucky Hit: Up to a 100% chance to lose all of your Resource.",
    "Lucky Hit: Up to a 20% chance to Freeze enemies for 3 seconds and deal X_0 Cold damage to them.",
    "Lucky Hit: Up to a 30% chance to form an exploding Ice Spike, dealing X_0 Cold damage. Triple this chance if the enemy is Frozen.",
    "Lucky Hit: Up to a X_0% chance to Freeze enemies for 3 seconds.",
    "Lucky Hit: While you have an active Bone Storm, hitting an enemy has up to a X_0% chance to spawn a Bone Storm around their location. Each Sacrifice bonus increases this chance by 25% and allows you to spawn 1 additional Bone Storm. Each active Bone Storm grants 20% Critical Strike Damage.",
    "Lucky Hit: Your damage over time effects have up to a 75% chance to erupt, dealing X_0 damage of the same type to surrounding enemies.",
    "Lupine Ferocity's Critical Strike Damage is increased to X_0%[x] for consecutive Critical Strikes.",
    "Meteor's Mana cost is replaced with a X_0 second Cooldown and 3 total Charges. Casting Meteor drops 3 additional Meteors around the target. Its Enchantment and Enhancement drop 1 additional Meteor instead.",
    "Mobility Skills are always Shadow Imbued with X_0%[x] increased potency. Lucky Hit: Damaging an Elite or Boss with a Mobility Skill has up to a X_0% chance to instantly triggers a free Shadow Imbuement explosion.",
    "Objects will often break around you",
    "Penetrating Shot makes enemies Vulnerable for 3 seconds. Every 4th cast of Penetrating Shot bounces off walls and deals X_0%[x] more damage.",
    "Rapid Fire now lobs exploding arrows that deal X_0%[x] increased damage.",
    "Rend's duration is increased by X_0 seconds. Damaging enemies with your Brawling Skills applies 2 stacks of Rend's Bleed. This can only affect each enemy once every 1 second.",
    "Sever leaves behind Desecrated Ground that deals X_0 Shadow damage over 2 seconds.",
    "Shapeshifting into a new animal form increases the value of your Bestial Rampage bonuses by X_0%[+], up to X_1%[+]. This bonus decays by 2% per second.",
    "Skills using this weapon deal X_0%[x] increased damage per point of Fury you have, but you lose 10 Fury every second.",
    "Soulrift's duration is increased by 0.5 seconds for every 30 Essence you spend while it's active, up to 8 seconds. Soulrift deals X_0% of its Shadow damage per second to surrounding enemies for every 30 Essence you gain while its active.",
    "Spending Vigor Heals you for X_0% of your Maximum Life. Every second, your active Cooldowns each drain 10% Maximum Life from you to reduce their durations by 2 seconds.",
    "Steel Grasp launches 2 additional chains. Enemies hit by Steel Grasp take X_0%[x] increased damage from you for 5 seconds.",
    "The first direct damage you deal to an enemy is a guaranteed Critical Strike. When you consume stacks of Precision casting a Skill, that Skill gains X_0%[x] increased Critical Strike Damage and you gain X_1 Energy.",
    "This is the descriptive text for the Unique Power 1 affix.Lucky Hit: Your ranged attacks have up to a 30% chance to deal X_0% additional damage and knock the target back.",
    "Thorns has a 10% chance to deal X_0%[x] increased damage.",
    "Upheaval ignites the ground Burning enemies for an additional X_0 damage over 4 seconds increased by 25%[x] for every 100 Strength you have.Current Bonus: 0%[x]",
    "Upon collecting Crackling Energy, there's a 15% chance to release a lightning nova, dealing X_0 Lightning damage, increased by 120%[x] for every 100 Intelligence you have.Current Bonus: 0%[x]",
    "Using 3 consecutive Basic Skills creates a 5 second mark on the ground under the first enemy is hit. Moving away from the mark increases Critical Chance up to X_0. The bonus ends when the mark disappears, and a new mark can only be created after the previous one vanishes.",
    "Werebear form is now your true form, and you gain X_0 Ranks to all Werebear Skills.",
    "Werewolf form is now your true form, and you gain X_0 Ranks to all Werewolf Skills.",
    "When activating or refreshing the full Walking Arsenal Key Passive effect, you automatically cast Ground Stomp and gain X_0 Fury. Can only occur once every 5 seconds.",
    "When casting an Ultimate Skill and again 5 seconds after, you Pull in Distant enemies and deal X_0 Physical damage to them. This damage is increased by 10.00%[x] per 1 point of Willpower you have.Current Bonus: 0%[x]",
    "When consuming a Corpse, there is a X_0% chance to also create a decaying Skeletal Simulacrum that seeks enemies but cannot attack. When it dies, it explodes for 450 Shadow damage. This effect is treated as a Macabre Skill.",
    "When you Critically Strike, you regain X_0%[+] of the Vigor you've spent in the last 2 seconds.Gain Counterattack's Passive Effect.",
    "When you Stun, Freeze, or Immobilize an Elite, you Pull In all Nearby enemies and deal X_0%[x] increased damage to them for 3 seconds. Can only occur once every 12 seconds.Against Bosses, this effect triggers when dealing damage instead.",
    "When you cast a Skill with a Cooldown, you explode, dealing X_0 Fire damage.",
    "When you cast an Ultimate Skill, your next Core Skill consumes all of your Energy and deals X_0%[x] increased damage per Energy consumed.Using a Cooldown restores 25 Energy.",
    "When you gain Berserking while already Berserk, you have a X_0% chance to become more enraged granting 25%[x] increased damage, 2 Fury per second, and 10% Cooldown Reduction.",
    "When you hit a Healthy enemy with an Earth Skill, Petrify them for 3 seconds. Against Bosses, this can occur once every 5 seconds instead. Petrifying an enemy additionally deals X_0 Physical damage.",
    "When you pick up X_0 Blood Orbs, a free Bone Spirit is spawned, dealing bonus damage based on your current Life percent.",
    "When you take damage from a Non-Physical damage type, you gain X_0%[+] Maximum Resistance to that damage type for 6 seconds. This effect can only apply to one damage type at a time.",
    "When you would be damaged for at least 40% of your Maximum Life at once, it is instead distributed over the next 3 seconds and reduced by X_0%.",
    "When you would take direct damage, it is instead distributed as Poisoning over the next 10 seconds but increased by X_0%.Lucky Hit: Direct damage has up to a 25% chance based on your Poisoned Life percentage to infect an enemy with Touch of Death.",
    "While Cataclysm is active, you gain unlimited Spirit and deal X_0%[x] increased damage.Cataclysm receives your Lightning Bolt benefits.",
    "While Channeling Incinerate, you periodically shoot embers that are attracted to enemies, each dealing X_0 Fire damage.",
    "While Channeling Stone Burst and for 2 seconds afterwards, you gain 15% Damage Reduction.Stone Burst's final explosion deals X_0%[x] increased damage, further increased by X_0%[x] for each size increase.",
    "While mounted you take 45% less damage.",
    "While mounted, gain a shield after not taking damage for 8 seconds, absorbing up to 15% of your Maximum Life. The shield goes away after dismount.",
    "While mounted, you stun the attacker for 4 seconds when you get hit.",
    "While moving, you gain X_0% Dodge Chance.After standing still for 2 seconds, you become Unstoppable until you move.",
    "While using this weapon, damaging at least one enemy with Rupture creates a blood pool that inflicts X_0 Bleeding damage over 6 seconds. Enemies standing in the pool take 70%[x] increased damage.",
    "While you have at least 4 stacks of Ferocity, you gain X_0 Vigor per second.Gaining Ferocity also grants Resolve.",
    "While you have unlimited Energy from Inner Sight, casting a Core Skill has a X_0% chance to spawn Caltrops, Poison Trap, or Death Trap. Gain X_1%[x] Core Skill Damage.",
    "While your Spirit Hall choices match: Their bonuses are 100% more potent. Skills of their Base Spirit gain X_0% Vigor Cost Reduction. Skills of their Base Spirit gain X_0% Cooldown Reduction.",
    "Whirlwind explodes every 2 seconds and after it ends, dealing X_0% of the Base damage dealt to surrounding enemies as Fire damage.",
    "You are Blood Lanced, and when Blood Lance would deal damage to you, it instead Fortifies you for X_0% of your Maximum Life and has a 10% chance to form a Blood Orb.Blood Lance deals X_1%[x] increased damage.",
    "You automatically activate the following equipped Skills on Corpses around you:Raise Skeleton every X_0 seconds.Corpse Explosion every X_0 seconds.Corpse Tendrils every X_2 seconds.",
    "You continually pulse Thorns damage every 5 seconds. The frequency increases up to 1s for 10 enemies.",
    "You deal X_0%[x] increased damage while Unstoppable and for 5 seconds after. When you become Unstoppable, gain 50 of your Primary Resource.",
    "You gain X_0%[x] increased Lucky Hit Chance against Crowd Controlled enemies.",
    "You leave behind a trail of frost that Chills enemies. You deal X_0%[x] more damage to Chilled enemies.",
    "Your Ancients are empowered:Korlic creates an Earthquake that deals X_0 Physical damage over 4 seconds when he leaps.Talic leaves behind Dust Devils that deal X_1 damage while he whirlwinds.Madawc ignites the earth, Burning enemies for an additional X_0 damage over 4 seconds when he upheaves the ground.",
    "Your Basic Skills deal X_0% increased damage and always use their 3rd attack. Every 3rd cast of a Basic Skill strikes three times.",
    "Your Core Skills are now additionally Basic Skills and free to cast.When cast at Maximum Vigor, your Core Skills consume all Vigor to cast at their largest Size, and become guaranteed Critical Strikes, with X_0%[x] increased Critical Strike Damage for each point of Vigor spent this way.",
    "Your Core Skills deal X_0%[x] increased damage when spending 3 Combo Points. Your Basic Skills using this weapon have a 50% chance to generate 3 Combo Points.",
    "Your Critical Strike Chance is increased by X_0% of your Movement Speed bonus.Current Bonus: 0.0%",
    "Your Darkness Skills Chill enemies for up to 100% and deal X_0%[x] increased damage to Frozen enemies.Lucky Hit: Your Darkness Skills have up to a 100% chance to generate X_1 additional Essence against Frozen targets.",
    "Your Earth Skills are now also Werebear Skills and Fortify you for X_0% of your Maximum Health.",
    "Your Evade is now Soar.Soar deals X_0%[x] increased damage.",
    "Your Focus Skills benefit from all their Upgrades, and they increase the damage of your Potency Skills by X_0%[x] for 8 seconds, up to X_1%[x].",
    "Your Healing Potion no longer heals instantly and instead grants a Barrier for 200% of the original healing for 4 seconds. You gain X_0% Damage Reduction while you have a Barrier and may drink your Healing Potion while at full Life.",
    "Your Maximum Vigor is increased by 50%, and killing an enemy grants you X_0 Vigor. While you have Ferocity, your Poisoning effects deal their damage across 33% of the normal duration.",
    "Your Rain of Arrows is always Imbued with all Imbuements and receives your Arrow Storm benefits.Gain X_0%[x] increased damage to Distant enemies.",
    "Your Skills gain X_0%[+] Critical Strike Chance per point of Primary Resource you have when cast, up to X_1%[+]. Each point of Primary Resource above 100 grants Skills 0.2%[x] Critical Strike Damage instead.",
    "Your Subterfuge Skills create an attackable Shade Totem for X_0 seconds. Any base damage it takes is replicated onto surrounding enemies at 20% effectiveness. This effect is treated as a Trap Skill. You may only have 1 Shade Totem active at a time.",
    "Your Summons have a X_0% chance to randomly inflict Decrepify or Iron Maiden when they deal damage.  You deal X_1%[x] increased Critical Strike Damage to enemies affected by your Curses.",
    "Your Weapon Mastery Skills are now also Core Skills that have no Cooldowns but cost Fury, dealing X_0% of normal damage. Their Fury costs are reduced by 5 for any additional Charges the Skill would have had.",
    "Your Wolf Companions are infused with the power of the storm, dealing Lightning damage and gaining the Storm Howl ability.",
    "Your attacks randomly deal 1% to X_0% of their normal damage.",
    "Your casts of Charged Bolts have a X_0% chance to be attracted to enemies and last 300% longer.",
    "Your mount can move through monsters and players.",
    "Your mount is immune to any crowd control effects.",
    "Your mount leaves a frost trail that chills enemies caught inside it.",
]
