//created 20250122
export const D4_ASPECTS = [
    "A dark aura surrounds you, inflicting Decrepify and Iron Maiden on enemies who touch it. Curses inflicted this way spread to surrounding targets every X_0 seconds and last 4 seconds outside of the aura.",
    "A hail of Meteorites falls during Inferno, dealing X_0 Fire damage on impact. Your Meteorites Immobilize enemies for 4 seconds.While Inferno is active, your Meteorites deal X_1%[x] increased damage.",
    "After Charged Bolts hits enemies 50 times, your next 3 casts of Charged Bolts become waves that pierce and critically surge upon impact.",
    "After Critically Striking an enemy with Shred, you gain 15 Spirit. While at 100 Spirit, your Werewolf Skills deal X_0%[x] increased damage until you go below 30 Spirit.",
    "After Immobilize or Stun wears off, enemies are Slowed by X_0% for 4 seconds.",
    "After attacking enemies with a Basic Skill, increase the damage of your next Core Skill cast within 5 seconds by X_0%[x], up to 30%[x].",
    "After being damaged for at least 20% of your Maximum Life at once, you become Immune for X_0 seconds. Can only occur once every 20 seconds.",
    "After casting 5 Basic Skills, one of your active Cooldowns is reduced by X_0 seconds.",
    "After channeling Incinerate for 2 seconds it deals X_0%[x] increased damage for 5 seconds. Casting Incinerate refreshes and maintains this bonus.",
    "After creating X_0 Ball Lightnings, your next Ball Lightning implodes Pulling In surrounding enemies before dealing 135 Lightning damage in a small area. This damage scales with Ball Lightning.",
    "After generating 100 Fury your next direct damage creates 2 Dust Devils that deal X_0 damage to enemies along their path.",
    "After hitting 15 enemies with your Shapeshifting Skills, your next Werebear Skill will deal X_0%[x] more damage and Stun enemies for 3 seconds.",
    "After spending 100 Fury, your next Weapon Mastery or Brawling Skill within 8 seconds deals X_0%[x] increased damage.",
    "After swapping weapons 5 times, gain X_0 Fortify.",
    "After swapping weapons 8 times, your next non-Basic Skill will Overpower and deal X_0%[x] increased Overpower damage.",
    "After you Evade, your next Core Skill dashes to your target and does X_0%[x] increased damage.",
    "Barrage arrows now pierce through 1 enemy.Barrage has X_0%[+] increased Attack Speed.",
    "Basic Skills gain X_0%[+] Attack Speed.",
    "Basic Skills grant 20.0% Damage Reduction for X_0 seconds.",
    "Becoming Injured while Crowd Controlled grants you Unstoppable for 4 seconds. This effect has a X_0 second Cooldown.",
    "Blight's defiled area Pulls In enemies and has a X_0% chance to Stun them for 3 seconds when it appears.",
    "Blood Howl increases Critical Strike Chance by X_0%[+]. In addition, Blood Howl also affects Nearby Players for 3 seconds.",
    "Blood Lance deals X_0%[x] increased damage to its primary target per lanced enemy. While Healthy, your Blood Lances deal X_1%[x] increased Critical Strike Damage.",
    "Blood Mist leaves a trail of Desecrated Ground that lasts for 4 seconds and deals X_0 Shadow damage per second. Gain 20%[+] Movement Speed while Blood Mist is active.",
    "Blood Mist triggers Corpse Explosion on surrounding Corpses. When Blood Mist detonates a Corpse, its Cooldown is reduced by X_0 seconds.",
    "Blood Orbs grant X_0 Essence.",
    "Blood Orbs reduce your Ultimate Cooldown by X_0 seconds.",
    "Blood Surge's nova echoes again after a short delay, dealing X_0%[x] less damage.",
    "Blood Wave fires two additional waves, each dealing X_0% of normal damage, but you can no longer recast it until the last wave ends.",
    "Bone Prison is now a Darkness Skill and spawns a pool of Blight that deals X_0%[x] bonus damage over 6 seconds.",
    "Bone Spear is guaranteed to Overpower enemies within Bone Prison and deals X_0%[x] increased damage to them.",
    "Bone Spear's primary attack makes enemies hit beyond the first Vulnerable for 3 seconds. Bone Shards from Bone Spear deal X_0% bonus damage to Vulnerable enemies and pierce them.",
    "Bone Spirit's Critical Strike Chance is increased by X_0%[+].Each enemy hit by Bone Spirit increases your Maximum Essence by 2, for 15 seconds, up to 50 Essence.",
    "Bone Storm and Blood Wave are also Darkness Skills, deal Shadow damage, and gain additional effects:  Enemies damaged by Bone Storm take X_0 Shadow damage over 2 seconds.  Blood Wave creates Desecrated Ground as it travels, dealing X_1 Shadow damage over 4 seconds",
    "Bone Storm consumes up to 8 Corpses to increase its duration by up to X_0 seconds.",
    "Boulder is now also a Core Skill and costs 40 Spirit to cast dealing X_0% of normal damage.",
    "Caltrops is now also a Marksman Skill that also throws X_0 piercing daggers over its duration, each dealing 15 Shadow damage and applying Vulnerable for 3 seconds.",
    "Caltrops, Smoke Grenade, and Death Trap receive your Stun Grenade benefits and throw Stun Grenades that deal X_0 Physical damage and Stun enemies for 1 second.Your Stun Grenades deal X_1% increased damage.",
    "Casting Blood Lance will consume Blood Orbs to also conjure lances from them. Each additional Blood Lance deals X_0% of normal damage and prioritizes targeting un-lanced enemies.",
    "Casting Bone Spirit also launches 18 Bone Splinters in all directions, dealing X_0%[x] increased damage and generating 6 Essence per enemy hit.",
    "Casting Charge Summons 4 Ancients to Charge with you, each dealing X_0%[x] of normal damage.Your Summons deal X_0% increased damage.",
    "Casting Concealment grants 10%[+] Resistance and Maximum Resistance to All Elements for 6 seconds.Killing an Elite enemy reduces Concealment's Cooldown by X_0 seconds.",
    "Casting Double Swing creates a Dust Devil that deals X_0 damage to enemies in its path.",
    "Casting Golem's active Skill creates a bond between you for 6 seconds. While the bond is active, both of you are Unhindered and gain X_0% Movement Speed.",
    "Casting Hurricane also spawns a smaller hurricane on your Wolves for 8 seconds. These hurricanes deals X_0% of Hurricane's damage for each active Wolf.",
    "Casting Ice Armor makes you Unstoppable and grants 25% Damage Reduction for X_0 seconds.",
    "Casting Pyromancy, Shock, and Frost Skills conjures a matching Elemental Dagger around you that pierces through enemies dealing X_0 damage after 3 seconds. The damage increases by 100% per matching elemental skill you cast.",
    "Casting Rushing Claw extends the durations of your active Incarnate Skill effects by X_0 seconds, up to X_1 seconds.",
    "Casting a Basic Skill reduces the Mana cost of your next Core or Mastery Skill by X_0%.",
    "Casting a Companion Skill grants you X_0%[+] Movement Speed for 5 seconds, up to X_1%[+].",
    "Casting a Conjuration Skill grants you X_0%[x] Damage Reduction for 5 seconds.",
    "Casting a Focus Skill recalls all Nearby Storm Feathers, dealing X_0 Lightning damage to enemies along their way. If there are no Nearby Storm Feathers, the cast instead spawns 10 randomly around you.",
    "Casting a Gorilla Skill increases your Weapon Damage by X_0% of your Armor for 3 seconds. Maximum 1,500 bonus Weapon Damage.",
    "Casting a Non-Basic Mobility Skill grants X_0 Resolve and causes your next Concussive Stomp to strike in a circle around you.",
    "Casting a Pyromancy Skill Immobilizes enemies hit for X_0 seconds.Casting a Shock Skill grants a burst of X_1% Movement Speed for 1 second.Casting a Frost Skill grants a Barrier for X_2% of your Maximum Life.While Enlightened, each Skill grants all benefits.",
    "Casting a Pyromancy, Shock, or Frost Skill increases your Mana Regeneration by X_0%[x] for 5 seconds, once per element.At maximum stacks, the total bonus is increased to X_1%[x] for 10 seconds, but all stacks expire afterwards.",
    "Casting a Storm Skill grants your Earth Skills X_0%[x] Critical Strike Damage for 4 seconds.Casting a Earth Skill increases the Critical Strike Chance of Storm Skills by X_1%[+] for 4 seconds.",
    "Casting an Ultimate Skill increases your Ultimate damage by X_0%[x], up to X_1%[x]. At 10 stacks, your Cooldowns and this bonus are reset.",
    "Casting an Ultimate Skill increases your damage by X_0%[x] for 8 seconds. Gain 2 additional Ultimate Skill Ranks.",
    "Chain Lightning has a 25% chance to deal X_0%[x] increased damage. This chance is doubled against Bosses or Crowd Controlled enemies and prefers them as targets.",
    "Chain Lightning has a X_0% chance to chain 5 additional times.",
    "Charge gains an additional Charge. Each target hit by it explodes for X_0 Fire damage to surrounding enemies.",
    "Charged Bolts pierce, but deal X_0%[x] less damage to targets hit after the first.",
    "Chilled enemies Poisoned by Poison Imbuement will be further Chilled for 20% per second. You deal X_0%[x] additional Poison damage to Frozen enemies.",
    "Close enemies or those you deal indirect damage to are Slowed by X_0% for 3 seconds. You deal X_1% increased damage to Slowed enemies.",
    "Collecting Crackling Energy increases your Movement Speed by X_0%[+] for 8 seconds.",
    "Coming in contact with your Firewall grants you X_0%[+] Movement Speed for 6 seconds.",
    "Companion Skills deal an additional X_0%[x] damage per Companion you have.",
    "Consuming a Corpse has a X_0% chance to spawn a Blood Orb.",
    "Consuming a Corpse increases the damage of your Core and Ultimate Skills by X_0%[x] for 5 seconds, up to X_1%[x].",
    "Core Skills cast at or above 100 Mana gain a X_0%[+] increased Critical Strike Chance.",
    "Crackling Energy has a X_0% chance to deal 40%[x] increased damage and chain to an additional enemy.",
    "Critical Hits with Lightning Spear cause the spear to arc to the target and up to 5 other enemies. This damage is increased by X_0%[x] of your Critical Strike Damage Bonus.",
    "Critical Strikes grant X_0%[+] Movement Speed for 1 second, up to 6 seconds.",
    "Critical Strikes with Bone Skills increase your Essence Regeneration by X_0%[x] for 4 seconds.",
    "Critical Strikes with Core Skills increase your Attack Speed by X_0%[+] for 5 seconds.",
    "Critical Strikes with Shred deal X_0% of the damage dealt as Lightning damage to the target and surrounding enemies.",
    "Critical Strikes with Storm Skills cause Lightning Bolts to periodically strike close enemies for 3 seconds, each dealing X_0 Lightning damage. Your Lightning Bolt damage is increased by 15%[x] for every 100 Willpower you have.Current Bonus: 0%[x]",
    "Cyclone Armor also provides Physical Damage Reduction. In addition, Cyclone Armor will also be applied to all Nearby Allies.",
    "Damage from Earth Skills Slow enemies hit by X_0% for 5 seconds.",
    "Damaging an Elite enemy grants you a Barrier absorbing up to X_0 damage for 10 seconds. This effect can only happen once every 20 seconds.",
    "Damaging an Elite enemy with a Basic Skill generates 4 Energy. Gain X_0 Energy after killing an Elite enemy with a Basic Skill.",
    "Damaging an enemy with Kick or Ground Stomp resets Leap's Cooldown.",
    "Damaging an enemy with a Basic Skill grants you 4% Attack Speed for 10 seconds, stacking up to 5 times. Upon reaching maximum stacks, you enter a Vampiric Bloodrage, gaining X_0%[x] Basic Skill damage and 15% Movement Speed for 10 seconds.",
    "Damaging enemies with Sever has a X_0% chance to spawn a pool of Blight under the target that deals X_1%[x] bonus damage. This effect can only happen once every 3 seconds.",
    "Dash Summons a Shadow Clone that also casts Dash, dealing X_0% of the Base damage.",
    "Deal X_0%[x] increased damage while you have a Barrier active.",
    "Deal X_0%[x] increased damage while you have no Defensive Skills on your Action Bar.",
    "Deal X_0%[x] increased damage. Triple this bonus after standing still for 3 seconds.",
    "Dealing direct damage with a Non-Agility Skill after Casting an Agility Skill grants Stealth for 2 seconds. When Stealth breaks, you gain X_0% Dodge Chance for 2 seconds.",
    "Debilitating Roar is now a Werewolf Skill. In addition, Debilitating Roar will Immobilize Poisoned enemies for X_0 seconds.",
    "Decompose can chain and burst up to 2 additional targets. Explosions from Decompose deal X_0%[x] increased damage.",
    "Distant enemies have a 10% chance to be Stunned for 2 seconds when they hit you. You deal X_0%[x] increased damage to Stunned or Knocked Down enemies.",
    "Each Chilled or Frozen enemy you Evade through grants you a Barrier that absorbs X_0 damage for 5 seconds, absorbing up to a maximum of X_1 damage.",
    "Each cast of Blood Lance will launch an additional Blood Lance at a Nearby enemy when it first hits an enemy that is already lanced, dealing X_0% of normal damage.",
    "Each different Basic Skill you cast increases all your damage by X_0%[x] for 10 seconds.",
    "Each percent of your Maximum Life you Heal beyond 100% grants you 0.5%[x] bonus Critical Strike Damage on your next Critical Strike, up to a X_0%[x] bonus.You may now drink your Healing Potion while at full Life.",
    "Each point of Fury generated while at Maximum Fury grants X_0 Fortify.",
    "Each point of Fury you generate while at Maximum Fury grants your next Core Skill within 5 seconds X_0%[x] increased damage, stacking up to 15 times.",
    "Each time Chain Lightning bounces, gain X_0 Mana.",
    "Each time a Core Skill deals direct damage to an enemy, your next Charge, Leap, or Kick deals X_0%[x] increased damage, up to X_1%[x].",
    "Each time one of your Minions damages an enemy, they gain X_0%[+] Attack Speed for 3 seconds, up to X_1%[+].",
    "Each time that Bone Storm damages an enemy, gain a Barrier equal to X_0% of your Maximum Life for 10 seconds.",
    "Each time the Shadowblight Key Passive deals damage to enemies, it increases the next Shadowblight's damage within 10 seconds by X_0%[x], stacking up to 5 times.",
    "Earth Skills deal X_0%[x] increased damage to Crowd Controlled enemies.",
    "Earth Spike deals X_0%[x] increased damage and launches spikes in a line.",
    "Enemies damaged by Kick or Charge will explode if they are killed within the next 2 seconds, dealing X_0 damage to surrounding enemies.",
    "Enemies hit by your Stun Grenades have a chance equal to your Critical Strike Chance to be Frozen for 2 seconds. You deal X_0%[x] increased Critical Strike Damage against Frozen or Stunned enemies.",
    "Enemies infected by Shadow Imbuement take X_0%[x] of its damage every second. Shadow Imbuement deals X_0%[x] increased damage.",
    "Enemies who move while Poisoned by you additionally take X_0% of your Thorns damage per second.",
    "Enemies you Poison have 100% Reduced Healing and your Eagle Skills gain X_0%[+] increased Critical Strike Chance against them.",
    "Evading through an enemy infected by Shadow Imbuement grants Stealth for 4 seconds. Breaking Stealth Heals you for X_0% of your Maximum Life.",
    "Every 20 seconds, your next Upheaval cast is guaranteed to Overpower and deals X_0%[x] increased damage. Upheaval Critical Strikes against Elites reduce this timer by 1 second.",
    "Every 3rd cast of Puncture is Poison Imbued with X_0% of normal potency.",
    "Every 3rd consecutive Crushing Hand smashes an additional 10 times around you dealing X_0% of its Base damage per hit.",
    "Every 5 seconds, your next Core Skill costs X_0 Life instead of Primary Resource. Will only occur if you have at least 60% of your Maximum Life.Skills that consume Life deal 80%[+] increased damage.",
    "Every 8 seconds, your Skeletal Mages cast a blizzard that deals X_0 Cold damage and continuously Chills enemies for 8% over 6 seconds.",
    "Every X_0 seconds, Distant enemies are Pulled In to you and take 10%[x] increased damage for 3 seconds. Will not pull target Bosses or while you are in Stealth.",
    "Every X_0 seconds, you zap a nearby enemy for 225 Lightning damage.This effect is treated as a Shock Skill.",
    "Every second while moving gain X_0%[+] Bonus Movement Speed for 1.5 seconds, up to X_1%[+].",
    "Explosions from the Shatter Key Passive deal X_0%[x] increased damage.",
    "Explosions from the Victimize Key Passive grant you a Barrier for X_0% of your Maximum Life for 5 seconds.",
    "Firewall Slows enemies within by 60%. After spending X_0 Mana your next Firewall is free to cast, destroys incoming Small Missiles, and Immobilizes anything hit with the initial Cast for 3 seconds.",
    "Flame Shield grants you Unhindered for its duration. Enemies you move through while Flame Shield is active are Immobilized for X_0 seconds.",
    "Flurry damages enemies in a circle around you and deals X_0%[x] increased damage.",
    "Flurry deals X_0%[x] increased damage and has a 20% chance to Stun enemies for 3 seconds with each hit.",
    "For every 10% Life you are missing, gain X_0% Damage Reduction.",
    "Frost Nova gains an additional Charge but the Cooldown per Charge is increased by X_0%.",
    "Frozen Orb deals X_0% increased damage and explodes an additional time.",
    "Gain +X_0 to Agility and Subterfuge Skills.",
    "Gain 1 Resolve when you Dodge an attack. Your Potency Skills consume 2 Resolve to deal X_0%[x] increased damage.",
    "Gain 1 additional Companion. In addition, your Companion Skills deal X_0%[x] bonus damage.",
    "Gain 10%[+] Block Chance. Blocking an attack from an enemy has a X_0% chance to cast a free Concussive Stomp at them.",
    "Gain 2%[+] increased Armor and X_0 Thorns per 1% missing Maximum Life.",
    "Gain X_0 Fortify whenever you deal direct damage while Berserking.",
    "Gain X_0 Thorns while Berserking.",
    "Gain X_0 Thorns while Channeling and for 3 seconds afterwards.",
    "Gain X_0 of your Primary Resource for every 20% of your Life that you Heal or every 120% Life that you Overheal while at Maximum Life.",
    "Gain X_0% Damage Reduction for each Nearby Bleeding enemy up to X_1% maximum.",
    "Gain X_0% Damage Reduction while Shapeshifted into a Werewolf.",
    "Gain X_0% Evade Cooldown Reduction. You can now Evade during Blood Mist, traveling twice as far. Entering or exiting Blood Mist resets your Evade Cooldown.",
    "Gain X_0% bonus amount to your next Earthen Bulwark for each enemy you Crowd Control up to a maximum of 100%.",
    "Gain X_0%[+] Block Chance per Resolve stack.",
    "Gain X_0%[+] Movement Speed. This bonus is tripled if you haven't used a Defensive Skill in the last 8 seconds.",
    "Gain X_0%[+] increased Critical Strike Chance for 6 seconds when you cast Corpse Tendrils. You deal X_1%[x] bonus Critical Strike Damage to enemies for 6 seconds after they are damaged by Corpse Tendrils.",
    "Gain X_0%[x] increased Fury Generation and 15% reduced Fury cost while all damage bonuses from the Walking Arsenal Key Passive are active.",
    "Gain X_0%[x] increased Lucky Hit Chance while all damage bonuses from the Walking Arsenal Key Passive are active.",
    "Gain X_0%[x] increased damage and Berserking for 4 seconds after swapping weapons 8 times.",
    "Gain X_0%[x] increased damage to a set of damage types for 7 seconds.  This effect alternates between 2 sets: Fire, Lightning, and Physical. Cold, Poison, and Shadow.",
    "Gain increased Critical Strike Damage equal to X_0%[x] of your Block Chance. Blocking doubles this bonus for 10 seconds.",
    "Gain the Flame Shield Enchantment for free. When Flame Shield activates, Meteorites fall around you dealing X_0 Fire damage.",
    "Grizzly Rage is now a Werewolf Skill and Shapeshifts you into a Dire Werewolf. Dire Werewolves gain X_0%[+] Movement Speed and X_1% Spirit Cost Reduction instead of Damage Reduction. In addition, kills Heal you for 10% of your Maximum Life.",
    "Ground Stomp creates an Earthquake damaging enemies for X_0 Physical damage over 4 seconds. While standing in Earthquakes and for 4 seconds afterwards, you deal X_1%[x] increased damage.Your Earthquakes deal X_1% increased damage.",
    "Hammer of the Ancients quakes outward for X_0%[x] increased damage. This bonus is doubled against Bosses.",
    "Heal for X_0% Maximum Life when you Poison a Healthy enemy.",
    "Hurricane damage is increased by X_0%[x] each second while active.",
    "Ice Blades' chance to apply Vulnerable is increased by 20%[+] and the Vulnerable duration is increased by 4 seconds. You gain X_0%[x] Vulnerable Damage.",
    "Ice Shards pierce 10 times, dealing X_0% more damage per subsequent enemy hit.",
    "Incinerate splits into 3 beams, each dealing X_0%[x] of normal damage.",
    "Increase the Critical Strike Damage of Meteor and Fireball by X_0%[x]. Double this bonus against Healthy targets.",
    "Increase the damage of arrows that ricochet from Barrage from 40% to X_0%. Barrage's arrows have a X_1% chance to split into 2 arrows whenever they ricochet.",
    "Increases your Maximum Spirit by X_0 and Spirit Generation by 50%[x] while Grizzly Rage is active.",
    "Iron Maiden is now also a Darkness Skill and deals Shadow damage. Enemies have a X_0% chance to be Stunned for 1 second when they take damage from Iron Maiden.",
    "Iron Skin grants Unstoppable, and X_0% Damage Reduction.",
    "Killing a Vulnerable enemy grants you X_0%[x] increased Energy Regeneration for 4 seconds.",
    "Killing an enemy while Berserking has a 40% chance to grant X_0% increased Cooldown Reduction to your Brawling Skills for 2 seconds.",
    "Killing an enemy with a Core Skill refunds X_0% of its base cost. Can only happen once per Skill cast.",
    "Kills extend the duration of your active Touch of Death swarms by 0.2 seconds. When a swarm is replaced early it bursts for X_0% of the damage it would have done.",
    "Kills have a 5% chance to summon a Wolf Companion to your side for X_0 seconds. This effect is a Lucky Hit against bosses. Maximum 3 additional wolves.In addition, gain +3 Ranks to Wolves.",
    "Knives from Dance of Knives have a 50% chance to shatter into 6 shards of metal on hit, dealing X_0 Physical damage.Dance of Knives now spends Combo Points, granting up to 3 additional Charges that can exceed the Maximum.",
    "Knocking Back an enemy grants you X_0% Movement Speed for 3 seconds.",
    "Landslide's earth pillars each strike a second time and deal an additional X_0% bonus damage per hit.",
    "Leap creates an Earthquake that deals X_0 Physical damage over 4 seconds. While standing in Earthquakes and for 4 seconds afterwards, you gain 30% increased Damage Reduction.Your Earthquakes deal X_1% increased damage.",
    "Lightning Storm Critical Strikes spawn 3 Dancing Bolts that seek enemies in the area dealing X_0 Lightning damage.",
    "Losing a stack of Resolve has a 25% chance to Heal you for X_0% of your Maximum Life.",
    "Lucky Hit: Centipede Skills have up to a 35% chance to spawn a Pestilent Swarm from the target which deals X_0 Poison damage per hit.Pestilent Swarms now also deal 100% of their Base damage as Poisoning damage over 6 seconds.",
    "Lucky Hit: Critical Strikes have up to a X_0% chance to grant a free Dark Shroud shadow.",
    "Lucky Hit: Damage from your Pyromancy Skills has up to a X_0% chance to restore 10 Mana.",
    "Lucky Hit: Damaging Bleeding or Vulnerable enemies has up to a X_0% chance to Stun them for 2 seconds.",
    "Lucky Hit: Damaging a Vulnerable enemy has a X_0% chance to drop a Healing Potion.",
    "Lucky Hit: Damaging a Vulnerable enemy has up to a 25% chance to create an Arrow Storm at the enemy's location , dealing X_0 Physical damage over 3 seconds. Your Arrow Storms deal X_1% increased damage.",
    "Lucky Hit: Damaging an enemy has up to a 20% chance to Daze them for 2 seconds.You deal X_0%[x] increased damage to Dazed enemies.",
    "Lucky Hit: Damaging an enemy with a Core Skill has up to a X_0% chance to extend the duration of Berserking by 2 seconds. Double this duration if it was a Critical Strike.",
    "Lucky Hit: Damaging an enemy with a Mobility Skill has up to a X_0% chance to fully restore your Energy. Your Maximum Energy is increased by X_0.",
    "Lucky Hit: Dealing damage to enemies affected by your Trap Skills has a X_0% chance to cause an explosion that deals X_1 Shadow damage and applies Vulnerable for 2 seconds.",
    "Lucky Hit: Dealing direct damage to a Vulnerable enemy has up to a X_0% chance to Daze them for 2 seconds.",
    "Lucky Hit: Eagle Skills have up to a X_0% chance to spawn a Storm Feather at your target. Each Storm Feather you pick up now also reduces your Mobility Skill Cooldowns by 0.5 seconds.",
    "Lucky Hit: Hitting an enemy has up to a X_0% chance to reduce your Evade Cooldown by 5 seconds. Each stack of Ferocity you have increases this chance by 5%.",
    "Lucky Hit: Leap, Upheaval, and Whirlwind have up to a X_0% chance to Summon an Ancient to perform the same Skill. Can only occur once every 5 seconds.Your Summons deal X_1% increased damage.",
    "Lucky Hit: Poison Imbued Skills have up to a 15% chance to create a toxic pool that deals X_0 Poisoning damage over 3 seconds to enemies within. While standing in the pool, your Poison Imbuement Skill has no Cooldown and no Charge limit.",
    "Lucky Hit: Shadow Imbued Skills have up to a 75% chance to create a toxic explosion that applies X_0 Poisoning damage over 5 seconds to the target and surrounding enemies. If they were already Poisoned, increase this damage by 100%[x].",
    "Lucky Hit: Shadow Imbued Skills have up to a 75% chance to release an explosion that deals X_0 Cold damage to the target and surrounding enemies, Chilling them for 25%. If they were already Chilled or Frozen, increase this damage by 100%[x].",
    "Lucky Hit: Up to a 10% chance to generate X_0 Essence when hitting a Vulnerable enemy.",
    "Lucky Hit: Up to a 100% chance when hitting or killing a Frozen enemy to create ice splinters that fires away from them dealing X_0 Cold damage and Chilling for X_1%.",
    "Lucky Hit: Up to a 25%[+] chance to create a Mystic Circle for 10 seconds. Casting a Centipede Skill in Mystic Circles empowers them to periodically apply X_0 Poisoning damage over 6 seconds to enemies within while extending any Crowd Controls affecting them.",
    "Lucky Hit: Up to a 25%[+] chance to create a Mystic Circle for 10 seconds. Casting a Eagle Skill in Mystic Circles empowers them to move with you and explode for X_0 Lightning damage when you Evade.",
    "Lucky Hit: Up to a 25%[+] chance to create a Mystic Circle for 10 seconds. Casting a Gorilla Skill in Mystic Circles empowers them to periodically grant you a Barrier for 25% of your Maximum Life which increases your Armor by X_0%[+] while active.",
    "Lucky Hit: Up to a 25%[+] chance to create a Mystic Circle for 10 seconds. Casting a Jaguar Skill in Mystic Circles empowers them to keep your Ferocity at Maximum and increase your damage by X_0%[x] per stack while you remain within.",
    "Lucky Hit: Up to a X_0% chance for your Skills to inflict Vampiric Curse on enemies. Enemies afflicted with Vampiric Curse are also Vulnerable.Vampiric Curse's stored souls deal X_1% increased damage.",
    "Lucky Hit: Up to a X_0% chance to reduce the Cooldown of your Ultimate Skill by 2 seconds. Can only happen once per Skill cast.",
    "Lucky Hit: Up to a X_0% chance when dealing Lightning damage to overload the target for 3 seconds, causing any direct damage you deal to them to pulse X_1 additional damage to surrounding enemies.",
    "Lucky Hit: When you hit a Crowd Controlled enemy, there is up to a X_0% chance for that Crowd Control effect to spread to another unaffected enemy.",
    "Lucky Hit: When your Conjuration Skills hit you have up to a X_0% chance to gain +1 Rank to your Conjuration skills for 12 seconds.  This can stack up to 5 times.",
    "Lucky Hit: You have up to a X_0% chance to gain 20 Fury when a Core Skill deals direct damage to at least one Bleeding enemy. Your Maximum Fury is increased by X_1.",
    "Lucky Hit: Your Marksman and Cutthroat Skills have up to a 25% chance to create an Arrow Storm at the enemy's location, dealing X_0 Physical damage over 3 seconds. Your Arrow Storms deal X_1% increased damage.",
    "Lucky Hit: Your Shadow Damage Over Time effects have a X_0% chance to generate 3 Essence. Damage from your Shadowblight Key Passive will always generate 2 Essence.",
    "Maximum Ferocity increased by X_0.",
    "Meteorites fall around Meteor, dealing X_0% of Meteor's damage on impact. Your Meteorites additionally Burn enemies they hit for X_1 damage over 6 seconds.",
    "Mighty Throw deals X_0%[x] increased damage and hurls another 2 weapons.",
    "Moving with a Mobility Skill or Evade spawns Storm Feathers along behind you.Storm Feathers now drop from the sky as they are spawned, each dealing X_0 Lightning damage on impact.",
    "Once an enemy is Slowed by at least 80%, they also become Feared for 3 seconds. You deal X_0%[x] increased damage to Feared enemies.",
    "Payback now echoes forward and additionally deals X_0% of your Thorns damage to enemies.",
    "Poison Creeper's active also casts Landslide in a circle around you. Earth Skills deal X_0% increased damage to Poisoned enemies.",
    "Poison Trap no longer breaks Stealth and triggers no Cooldown or arm time while you are in Stealth. All Poison Traps activate when you exit Stealth and Poison Trap's Cooldown will be X_0 seconds per trap placed.",
    "Pulverize creates a shockwave that travels forward, dealing X_0% of its damage to targets in the path.",
    "Pulverize is now also an Earth Skill. After casting Pulverize, tectonic spikes continue to deal X_0 damage over 2 seconds.",
    "Quill Volley's feathers explode at their apex and return to where they were cast, both dealing X_0% of their normal damage.",
    "Rake manifests a Spirit Jaguar which also casts Rake at a random enemy, dealing X_0% Base damage.",
    "Rapid Fire has a X_0% chance to ricochet to another target.",
    "Ravager no longer has a duration and instead drains X_0 Vigor every second until depleted. Effects which extend Ravager instead generate Vigor.",
    "Ravens now deal Lightning damage which is increased by X_0%. Ravens Active deals its full damage in half the time, and enemies inside it are Stunned.",
    "Razor Wings hits increase your Eagle Skill damage by 1%[x] for 4 seconds, stacking up to X_0%[x]. Razor Wing Critical Strikes grant 3 stacks.",
    "Razor Wings is now also an Incarnate Skill with:Passive: You are Unhindered.Active: You gain X_0%[+] increased Movement Speed per active Razor Wing.",
    "Reduces the Cooldown of Leap by X_0 seconds per enemy hit, up to a maximum of 7 seconds. Hitting a Boss with Leap provides the maximum reduction.",
    "Restore X_0 of your Primary Resource when you Crowd Control an enemy.",
    "Scourge continuously affects enemies around you for X_0 seconds.",
    "Sever no longer returns and instead splits into 3 specters that expand out from its apex. Sever deals X_0% of normal damage.",
    "Sever now dashes you forward to attack instead. It is now also a Mobility Skill and costs no Essence, but has a X_0 second Cooldown.",
    "Shadow Step has an additional Charge. Killing an enemy with Shadow Step refunds a Charge and increases the damage of Shadow Step by X_0%[x] for 2 seconds, up to X_1%[x].",
    "Shapeshifting into a new animal form causes a Lightning Bolt to strike a Nearby enemy dealing 600 Lightning damage.Your Lightning Bolts deal X_0%[x] increased damage and Stun for 2 seconds.",
    "Shred deals X_0%[x] more damage, and Shred has a 33% chance to land an additional strike, up to 4 times.",
    "Shred gains a 4th dash attack that hits all surrounding enemies, deals X_0%[x] increased damage, and Knocks Down enemies for 2 seconds.",
    "Skeletal Priests empower your Skeletal Warriors' attacks to deal Shadow damage and have a X_0% chance to Stun enemies for 1.5 seconds.",
    "Skills deal up to X_0%[x] increased damage based on your available Primary Resource when cast, receiving the maximum benefit while you have full Primary Resource.",
    "Smoke Grenade has a X_0% chance to create an Arrow Storm where it explodes, dealing X_1 Physical damage over 3 seconds. Your Arrow Storms continuously apply Vulnerable.",
    "Soar casts Vortex at its landing location and deals X_0%[x] increased damage.",
    "Steel Grasp and Iron Maelstrom launch up to 10 metal shards that deal X_0 Physical damage.",
    "Stinger spawns a Pestilent Swarm at its target location which deals X_0% of Stinger's Base damage per hit.Pestilent Swarms now last 50%[+] longer and spiral outwards.",
    "Stone Burst causes enemies to take X_0%[x] increased damage from your other Skills for 5 seconds. Stone Burst's Spirit cost is reduced by 10.",
    "Stone Burst deals X_0%[x] increased damage and when Cast at or above 75 Spirit, it is immediately at its maximum size.",
    "Stunning a Bleeding enemy deals X_0% of their total Bleeding amount to them as Physical damage.",
    "Taking direct damage has a X_0% chance to reset the Cooldown of one of your Defensive Skills.",
    "Teleport's Cooldown is reduced by X_0 seconds. After Teleporting, Crackling Energy hits 2 additional enemies for 5 seconds.",
    "The Avalanche Key Passive now applies to 2 additional casts and its damage to Vulnerable enemies is increased to X_0%[x].",
    "The Earthen Might Key Passive also applies to your Storm Skills.",
    "The Ossified Essence Key Passive also increases the Critical Strike Damage of your Bone Skills by 1%[x] per Essence above 50, up to X_0%[x].",
    "The Vyr's Mastery Key Passive also grants X_0% Damage Reduction, tripled while fighting a Close enemy.",
    "The duration of Earthen Bulwark is increased by 6 seconds. In addition, killing an enemy replenishes X_0 of your active Earthen Bulwark's Barrier.",
    "The duration of Grizzly Rage is increased by X_0 seconds. In addition, Critical Strikes while Grizzly Rage is active increase your Critical Strike Damage by 10%[x] for the duration, up to a maximum of 200%[x].",
    "Thorns damage dealt has a X_0% chance to deal damage to all enemies around you and your Minions.",
    "Tornado will seek up to X_0 targets and deals X_1%[x] increased damage.",
    "Trample now sunders the earth, forming 6 Landslide pillars that deal X_0% of normal damage. Trample is now also a Nature Magic and Earth Skill.",
    "Twisting Blades orbit for a short time after they return to you, dealing X_0% of Twisting Blades' return damage per hit.",
    "Twisting Blades' initial hit cleaves. Twisting Blades return X_0% faster.",
    "Unstable Currents has a X_0% chance to cast an additional Shock Skill.",
    "Upon taking damage from surrounding enemies, you drop a Smoke Grenade and Dodge the next X_0 attacks within 5 seconds. Can only occur once every 10 seconds.",
    "Using a Cooldown restores X_0 Mana.",
    "Using a Healing Potion grants X_0%[+] Movement Speed for 3 seconds, and you may now drink them while at full Life.Every 20 meters you travel, you spawn a Healing Potion.",
    "Using a Healing Potion makes a free Dark Shroud shadow. Each shadow grants X_0% increased Damage Reduction.You may now drink your Healing Potion while at full Life.",
    "Using an Agility Skill reduces the Cooldown of your next Subterfuge Skill by 20%. Using a Subterfuge Skill increases the damage of your next Agility Skill by X_0%[x].",
    "Werewolf Skills function as Storm Skills and Werebear Skills function as Earth Skills for the Nature's Fury Key Passive.",
    "When Bone Spirit explodes inside a Bone Prison, it explodes an additional time, dealing X_0% of normal damage. Bone Spirit now prioritizes enemies inside a Bone Prison.",
    "When Bone Spirit explodes, it spawns 3 spirits that seek nearby enemies and deal X_0% of its damage. This effect can only occur once per cast.",
    "When Decompose explodes, gain X_0 Essence.",
    "When Rabies infects an enemy, reduce its Cooldown by X_0 seconds, tripled when infecting Elite enemies. You deal X_1%[x] increased damage to enemies affected by Rabies.",
    "When a Dark Shroud shadow is removed you trigger an explosion that deals X_0 Shadow damage and applies Shadow Imbuement to each enemy it hits.",
    "When a segment of Bone Prison is destroyed or expires, it deals X_0 damage in an area around itself.",
    "When an enemy falls below 35% Life while afflicted with any of your damage over time effects, an explosion occurs every 7 seconds, dealing X_0 damage for each element they're afflicted by.",
    "When casting an Imbuement Skill you trigger an explosion around yourself, applying the Imbuement effects and dealing X_0 damage of the same type.",
    "When entering Stealth, create a cloud that deals X_0 Poison damage over 6 seconds.",
    "When falling below 20 Spirit, there is a X_0% chance to fully restore your Spirit.",
    "When hit while not Healthy, you form a protective bubble that grants all Players inside Immune. The bubble lasts for X_0 seconds and can only form once every 90 seconds.",
    "When newly Burning an enemy, you deal an additional X_0 Fire damage to them.This effect is treated as a Pyromancy Skill.",
    "When the Nature's Fury Key Passive triggers a free Skill, your non-Ultimate Cooldowns of the opposite type are reduced by X_0 seconds.",
    "When there are at least 5 Close enemies, Stun them for X_0 seconds. Can only occur once every 20 seconds.",
    "When used below 50% Maximum Resource, Basic Skills generate 3 of your Primary Resource, once per Skill.When used at or above 50% Maximum Resource, Basic Skills deal X_0%[x] increased damage.",
    "When you Dodge or Block an attack you trigger a X_0% Thorns attack against the attacker.",
    "When you Evade or Shadow Step, you drop a cluster of exploding Stun Grenades that deal X_0 total Physical damage and Stun enemies for 1.00 second.Your Stun Grenades deal X_1% increased damage.",
    "When you Evade you turn into a cloud of bats, becoming Unstoppable for 2.5 seconds. Enemies along your path take 300 Physical damage and are inflicted with Vampiric Curse.  Evade's Cooldown is increased by X_0 seconds.",
    "When you Freeze an enemy there is a X_0% chance they become Vulnerable for 3 seconds.",
    "When you Freeze an enemy, you deal X_0 Cold damage to them.This effect is treated as a Frost Skill.",
    "When you cast Blizzard it will periodically spawn exploding Ice Spikes that deal X_0 damage. Your Ice Spikes Chill enemies for 15%.",
    "When you cast a Skill, you heal for X_0% Life. Double this bonus while below 50% Life.",
    "When you cast an Incarnate Skill enemies around you become Vulnerable for 5 seconds. Killing a Vulnerable enemy echoes X_0% of the killing blow's damage to another Nearby enemy.",
    "When you cast the 3rd attack of your Basic Skills you have a X_0% chance to unleash Payback.",
    "When you enter or break Stealth, you drop a cluster of exploding Stun Grenades around you that deal X_0 total Physical damage and Stun enemies for 1.00 second.Your Stun Grenades deal X_1% increased damage.",
    "When you have Fortify your Earth Skills gain +2 Ranks.",
    "When you hit at least 1 enemy with Maul, increase its Attack Speed by X_0%[+] and you gain X_1%[x] Damage Reduction for 5 seconds, stacking up to 5 times.",
    "When you spend 75 Energy you release a cluster of exploding Stun Grenades that deal X_0 total Physical damage and Stun enemies for 1.00 seconds.Your Stun Grenades deal X_1% increased damage.",
    "When you use a Shapeshifting Skill that changes your form, Heal for X_0% of your Maximum Life. If you are at full Life, gain the same amount as Fortify.",
    "When you would accumulate 50% of your Maximum Life lost, you automatically cast an equipped Defensive, Subterfuge or Macabre Skill before receiving damage. Can only occur once every X_0 seconds and cannot cast Mobility Skills.",
    "When your Core Skills Overpower an enemy, you spawn 3 Volatile Blood Drops. Collecting a Volatile Blood Drop causes it to explode, dealing X_0 Physical damage around you. Every 20 seconds, your next Skill is guaranteed to Overpower.",
    "Whenever Penetrating Shot damages an enemy, 2 additional arrows split off to either side. These side arrows deal X_0% of Penetrating Shot's Base damage and do not split.",
    "Whenever an enemy hits you, your Dodge Chance against that enemy increases by X_0%[+]. Successfully Dodging resets this bonus.",
    "Whenever you cast a Shout Skill, its active Cooldown is reduced by X_0% per Nearby enemy, up to a maximum of 70.0%.",
    "Whenever you deal direct damage while Berserking, inflict X_0% of the Base damage dealt as additional Bleeding damage over 5 seconds.",
    "Whenever your Blood Skills Overpower you gain X_0%[+] Attack Speed for 4 seconds.",
    "While Army of the Dead is active, your Minions take 90% reduced damage and you deal X_0%[x] increased Summon Damage.",
    "While Berserking, you deal X_0 Fire damage every second to surrounding enemies.",
    "While Deep Freeze is active, exploding Ice Spikes form in the area, dealing X_0 Cold damage. Your Ice Spikes have a 50% increased explosion radius.",
    "While Deep Freeze is active, you restore X_0% of your Maximum Life and Mana per second.",
    "While Grizzly Rage is active, Skills that Critically Strike apply Rabies and your Poison damage is increased by X_0%.",
    "While Hurricane is active, gain +5 Ranks to your Shapeshifting Skills.",
    "While Ice Armor is active, you leave behind exploding Ice Spikes that deal X_0 damage. Your Ice Spikes deal 15%[x] increased damage to Frozen enemies.",
    "While Soulrift is active, you are Unhindered and gain 1%[+] increased Movement Speed per absorbed soul, up to X_0%[+].This lasts for 5 seconds after Soulrift ends.",
    "While Unstable Currents is not active, your Shock Skills have a X_0% chance to trigger a free cast from it.",
    "While Unstoppable and for 4 seconds after, you gain X_0%[+] increased Movement Speed and are Unhindered.",
    "While above X_0 Fury, you are Berserk, but lose 2 Fury per second.",
    "While active, Counterattack reflects incoming Razor Wings back out again. Other Small Missiles are also deflected back at enemies and deal X_0% of Counterattack's Base damage on impact.",
    "While at maximum stacks of the Momentum Key Passive, your Cutthroat Skills deal X_0% increased damage and you become Unstoppable for 3 seconds every 6 seconds.",
    "While below X_0% Life, Evade Freezes Close enemies, deals 450 Cold damage to them, and makes them Vulnerable for 3 seconds.",
    "While both bonuses from the Esu's Ferocity Key Passive are active, your Attack Speed is increased by X_0%.",
    "While channeling Incinerate, your Burning damage is increased by X_0%[x]. Enemies damaged by Incinerate explode, Burning all surrounding enemies for X_1 over 6 seconds. Can only occur once every 3 seconds.",
    "While in Werebear form, you gain X_0 Spirit every 8 seconds.",
    "While within your own Blizzard and for 3 seconds after leaving it, you take X_0% less damage.",
    "While you have active Minions, your Summons deal X_0%[x] increased damage, but each Minion drains 1 Essence from you per second. While you have no active Minions, you deal X_0%[x] increased damage, but you lose 7 Essence per second.",
    "While you have both bonuses from the Close Quarters Combat Key Passive active, your Dodge Chance increases by X_0% whenever you're hit by an enemy. Successfully Dodging resets this bonus.",
    "Whirlwind leaves behind Dust Devils that deal X_0 damage to enemies in their path.",
    "Whirlwind periodically Pulls enemies to you.",
    "Whirlwind's Critical Strike Chance is increased by X_0%[+] for each second it is channeled, up to X_1%[+].",
    "Wind Shear now deals X_0 Poisoning damage over 4 seconds.Lucky Hit: Wind Shear has up to a X_1% chance to fully restore your Spirit.",
    "X_0% of the damage you would take is redirected across your Companions. Your Companions cannot die.",
    "You automatically drop a Smoke Grenade at the end of Dash. Smoke Grenades reduce Dash's Cooldown by X_0 seconds for each enemy hit, up to X_1 seconds.",
    "You deal 9%[x] increased Burning damage to enemies for each second they remain Burning, up to 45%[x] after 5 seconds. Additionally, you deal X_0%[x] increased Burning damage to enemies that are not Healthy.",
    "You deal X_0%[x] increased Critical Strike Damage to enemies marked by Inner Sight. While Inner Sight is full, you gain X_1%[x] increased damage.",
    "You deal X_0%[x] increased Shadow damage to enemies afflicted by any Curse.",
    "You deal X_0%[x] increased damage for 6 seconds after the Shadowblight Key Passive damages enemies 10 times.",
    "You deal X_0%[x] increased damage over time to enemies for each different Crowd Control effect they're afflicted by. Unstoppable enemies and Staggered Bosses take X_1%[x] increased damage over time from you instead.",
    "You deal X_0%[x] increased damage while Ice Armor is active. This amount is increased by another 0%[x] against Frozen enemies.",
    "You deal X_0%[x] increased damage while hitting a Poisoned enemy as a Werebear or a Crowd Controlled enemy as a Werewolf.",
    "You deal X_0%[x] more damage to Immobilized, Stunned, or Frozen enemies.",
    "You gain 20% Movement Speed. Lose this bonus for X_0 seconds after taking damage from a Close enemy.",
    "You gain Resolve for each X_0 Vigor you gain while at Maximum Vigor. This can overfill your Resolve by 5 stacks.",
    "You gain X_0 Armor, but your Evade has 100% increased Cooldown.",
    "You gain X_0 Fury per second while Berserking.",
    "You gain X_0 Maximum Essence per active Minion.",
    "You gain X_0% increased Damage Reduction.",
    "You gain X_0%[+] increased Armor for 4 seconds when you deal any form of damage, stacking up to X_1%[+].",
    "You gain X_0%[x] increased Movement Speed when moving away from Slowed or Chilled enemies.",
    "You have 20%[+] increased Crowd Control Duration. While enemies are Unstoppable, you deal X_0%[x] increased damage to them.",
    "You have 8% increased Dodge Chance. Successful Dodges restore X_0% of your Maximum Life.",
    "You have 8% increased Dodge Chance. When you Dodge, Fortify for X_0% of your Maximum Life.",
    "You have X_0%[+] increased Critical Strike Chance against Injured enemies. While you are Healthy, you gain X_1%[+] increased Crowd Control Duration.",
    "You have a X_0% chance after taking direct damage to gain a Barrier equal to 20% of your Maximum Life for 10 seconds. This chance is doubled against Distant enemies.",
    "You have a X_0% chance to gain 1 Ferocity when you Crowd Control an enemy or apply any Stagger to a Boss.",
    "You may have 1 additional Hydra active, and Hydra's duration is increased by X_0%.",
    "You take X_0% less damage from Crowd Controlled enemies. Whenever a Crowd Controlled enemy deals direct damage to you, gain 15%[+] Movement Speed for 2 seconds.",
    "You take X_0% less damage from Crowd Controlled or Vulnerable enemies.",
    "Your Basic Skills are always Cold Imbued.You deal X_0%[x] increased damage to enemies who are Chilled or Frozen.",
    "Your Blocked Damage Reduction is increased by X_0%[+] while you have a Barrier.",
    "Your Companions gain double the bonus from the Bestial Rampage Key Passive.",
    "Your Core Skills deal up to X_0%[x]  increased damage based on your amount of Fortify.",
    "Your Critical Strike Chance with Jaguar Skills is increased by 15%[+] and X_0%[+] for each 10% of an enemy's missing Life.",
    "Your Defensive Skills Cooldown X_0% faster while you are moving.",
    "Your Earthquakes deal X_0%[x] increased damage and their damage is converted into Fire.",
    "Your Golem Active also causes your Golem to erupt, dealing X_0 Physical damage to surrounding enemies. Your Golem consumes Corpses to reduce its remaining Cooldown by 1 second.",
    "Your Golem has a X_0% chance to reduce its active Cooldown by 2 seconds and a X_1% chance to spawn a Corpse each time it damages an enemy with its normal attack.",
    "Your Grenade Skills count as Trap Skills. Whenever you arm a Trap or drop Grenades, you gain X_0%[+] increased Movement Speed for 3 seconds.",
    "Your Imbuement Skill effects have X_0%[x] increased potency.",
    "Your Lucky Hit Chance is increased by X_0%[+] while you have a Barrier active.",
    "Your Minions deal 38 Shadow damage per second to enemies around them. The damage over time lasts for X_0 seconds after leaving the aura.",
    "Your Minions gain increased damage while alive, up to X_0%[x] after 10 seconds.",
    "Your Overpowers will spawn an Earthquake every 4 seconds that deals X_0 Physical damage over 4 seconds. Your Earthquake damage is increased by 15%[x] for every 100 Strength you have. Current Bonus: 0%[x]",
    "Your Reinforcement Cooldown is reduced by X_0%[x]. After casting your Ultimate Skill, your Reinforcement Mercenary is called. This cannot occur more than once each 30 seconds.",
    "Your Resolve has a X_0% chance to not be lost when you are hit for each stack of Ferocity you have.",
    "Your Rupture does not remove the Bleeding damage from enemies. Can only occur once every X_0 seconds.",
    "Your Sacrifice bonuses are increased by X_0%.",
    "Your Shadow Clones also mimic the Imbuements applied to your Skills. Casting an Imbuement Skill grants your active Shadow Clone X_0%[x] increased damage for 9 seconds.",
    "Your Shout Skills create 3 Dust Devils that deal X_0 damage to enemies along their path.Your Dust Devils are X_1% bigger and deal 1%[x] increased damage for each 1% their size is increased.",
    "Your Shout Skills generate X_0 Primary Resource per second while active.",
    "Your Skeletal Priests now also empower you at X_0% effectiveness.",
    "Your Weapon Mastery Skills have an additional Charge.Lucky Hit: Damaging an enemy with a Weapon Mastery Skill has up to a X_0% chance to Stun them for 2 seconds.",
    "Your Wolf Companions are now Werewolf Companions. Werewolf Companions deal X_0%[x] additional damage and can spread Rabies.",
    "Your maximum number of Skeletal Warriors and Skeletal Mages is increased by 2.",
    ]
;
