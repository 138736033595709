import {contains, containsIgnoreCase} from "../Utils/ArrayUtils";

export const MAX_ITEM_POWER = 800;
export const MAX_HERO_LEVEL = 60;

const summonings = ["Duriel Set", "Andariel Set", "Exquisite Blood",
    "Malignant Heart", "Living Steel", "Distilled Fear", "Mucus-Slick Egg", "Shard of Agony", "Stygian Stone", "Pincushioned Doll",
    "Sandscorched Shackles"];

const summoningDescriptions = {
    "Duriel Set": ["Mucus-Slick Egg (2)", "Shard of Agony (2)"],
    "Andariel Set": ["Sandscorched Shackles (2)", "Pincushioned Doll (2)"],
}

//updated 2025-01-19
const elixirs = [
    "Acrobatics Elixir",
    "Assault Elixir",
    "Charred Demon Heart",
    "Crushing Elixir",
    "Draught of Whispers",
    "Elixir of Advantage II",
    "Elixir of Advantage",
    "Elixir of Aggression",
    "Elixir of Antivenin",
    "Elixir of Beast-slaying",
    "Elixir of Cold Resistance II",
    "Elixir of Cold Resistance",
    "Elixir of Combatant Fortune",
    "Elixir of Cruelty",
    "Elixir of Demon-slaying",
    "Elixir of Destruction II",
    "Elixir of Destruction",
    "Elixir of Fire Resistance II",
    "Elixir of Fire Resistance",
    "Elixir of Fortitude II",
    "Elixir of Fortitude",
    "Elixir of Hollow Unmaking",
    "Elixir of Holy Bolts",
    "Elixir of Iron Barbs II",
    "Elixir of Iron Barbs",
    "Elixir of Lightning Resistance II",
    "Elixir of Lightning Resistance",
    "Elixir of Magic Resist",
    "Elixir of Man-slaying",
    "Elixir of Momentum",
    "Elixir of Poison Resistance II",
    "Elixir of Poison Resistance",
    "Elixir of Precision II",
    "Elixir of Precision",
    "Elixir of Resourcefulness II",
    "Elixir of Resourcefulness",
    "Elixir of Sacrifice",
    "Elixir of Savagery",
    "Elixir of Shadow Resistance II",
    "Elixir of Shadow Resistance",
    "Elixir of Swift Revival",
    "Elixir of Tailwind",
    "Elixir of Undead-slaying",
    "Expertise Elixir",
    "Expired Elixir of Death Evasion",
    "Gileon's Brew",
    "Heady Acrobatics Elixir",
    "Heady Assault Elixir",
    "Heady Crushing Elixir",
    "Heady Third Eye Elixir",
    "Heady Vampirism Elixir",
    "Iron Barb Elixir",
    "Iron Skin Elixir",
    "Potent Acrobatics Elixir",
    "Potent Assault Elixir",
    "Potent Crushing Elixir",
    "Potent Elixir of Cold Resistance",
    "Potent Elixir of Fire Resistance",
    "Potent Elixir of Lightning Resistance",
    "Potent Elixir of Poison Resistance",
    "Potent Elixir of Shadow Resistance",
    "Potent Iron Barb Elixir",
    "Potent Precision Elixir",
    "Potent Third Eye Elixir",
    "Potent Vampirism Elixir",
    "Precision Elixir",
    "Profane Mindcage",
    "Seething Opal of Equipment",
    "Seething Opal of Gold",
    "Seething Opal of Materials",
    "Seething Opal of Socketables",
    "Seething Opal of Torment",
    "Spicy Paletongue",
    "Strong Acrobatics Elixir",
    "Strong Assault Elixir",
    "Strong Crushing Elixir",
    "Strong Elixir of Cold Resistance",
    "Strong Elixir of Fire Resistance",
    "Strong Elixir of Lightning Resistance",
    "Strong Elixir of Poison Resistance",
    "Strong Elixir of Shadow Resistance",
    "Strong Iron Barb Elixir",
    "Strong Precision Elixir",
    "Strong Third Eye Elixir",
    "Strong Vampirism Elixir",
    "Third Eye Elixir",
    "Vampirism Elixir",
    "Weak Acrobatics Elixir",
    "Weak Assault Elixir",
    "Weak Crushing Elixir",
    "Weak Iron Barb Elixir",
    "Weak Third Eye Elixir",
    "Weak Vampirism Elixir",
]


const magicRunes = [
    "Tec", "Gar", "Ceh", "Ton", "Tal", "Lum", "Met", "Ono", "Teb", "Tun", "Ixk", "Thar", "Moni", "Cem", "Cir", "Zan", "Yax", "Ur", "Hak"
]

const rareRunes = [
    "Que", "Kry", "Qax", "Xal", "Wat", "Tzic", "Lac", "Mot", "Zec", "Thul", "Ner", "Qua", "Ixt", "May", "Nam", "Cha", "Poc", "Feo", "Neo", "Kaa", "Noc", "Cip", "Pac", "Tza", "Chac"
]

const legendaryRunes = [
    "Ohm", "Eom", "Yom", "Jah", "Vex", "Xan", "Ehe", "Ura", "Xol", "Lith", "Bac", "Tam", "Ahu", "Yul", "Igni", "Nagu"
]

const invocationRunes = [
    "Que", "Ohm", "Eom", "Kry", "Yom", "Jah", "Qax", "Xal", "Tec", "Wat", "Gar", "Tzic", "Ceh", "Lac", "Vex", "Ton", "Xan", "Mot", "Zec", "Thul", "Tal", "Ner", "Lum", "Met", "Qua", "Ono", "Teb", "Ehe", "Tun", "Ixk", "Ixt", "Thar", "May", "Ura", "Nam", "Cha", "Chac"
]

const ritualRunes = [
    "Xol", "Lith", "Bac", "Poc", "Tam", "Moni", "Ahu", "Cem", "Yul", "Cir", "Feo", "Zan", "Yax", "Neo", "Kaa", "Noc", "Ur", "Cip", "Hak", "Pac", "Tza", "Igni", "Nagu"
]

const runes = invocationRunes.concat(ritualRunes);

const runeSets = [
    "Andariel's Visage",
    "Doombringer",
    "Harlequin Crest",
    "Heir of Perdition",
    "Melted Heart of Selig",
    "Ring of Starless Skies",
    "Shroud of False Death",
    "Tyrael's Might",
    "Shattered Vow",
    "Nesekem, the Herald",
    "The Grandfather",
    "Ahavarion, Spear of Lycander"
]

const runeSetDescriptions = {
    "Andariel's Visage": ["Tam (6)", "Qax (6)", "Zan (6)"],
    "Doombringer": ["Ahu (6)", "Neo (6)", "Lum (6)"],
    "Harlequin Crest": ["Eom (6)", "Lac (6)", "Ton (6)"],
    "Heir of Perdition": ["Jah (6)", "Que (6)", "Gar (6)"],
    "Melted Heart of Selig": ["Vex (6)", "Thul (6)", "Tun (6)"],
    "Ring of Starless Skies": ["Ohm (6)", "Tzic (6)", "Cem (6)"],
    "Shroud of False Death": ["Bac (6)", "Kry (6)", "Moni (6)"],
    "Tyrael's Might": ["Lith (6)", "Feo (6)", "Met (6)"],
    "Shattered Vow": ["Xan (6)", "Ner (6)", "Teb (6)"],
    "Nesekem, the Herald": ["Yul (6)", "Wat (6)", "Tal (6)"],
    "The Grandfather": ["Xol (6)", "Mot (6)", "Yax (6)"],
    "Ahavarion, Spear of Lycander": ["Yom (6)", "Poc (6)", "Cir (6)"],
}

const gems = [
    "Any Grand Gem",
    "Any Royal Gem",
    "Grand Diamond",
    "Grand Emerald",
    "Grand Ruby",
    "Grand Skull",
    "Grand Topas",
    "Grand Amethyst",
    "Grand Sapphire",
    "Royal Diamond",
    "Royal Emerald",
    "Royal Ruby",
    "Royal Skull",
    "Royal Topas",
    "Royal Amethyst",
    "Royal Sapphire"
]

const consumables = ["Abyssal Scroll"]

const summoning = "Summoning";
const rune="Rune";
const runeSet = "Rune Set";
const elixir = "Elixir";
const gem = "Gem";
const consumable = "Consumable";

export const getSummoningDescription = function (summoning) {
    const descriptions = summoningDescriptions[summoning]
    if (descriptions) {
        return descriptions;
    } else {
        return [];
    }
}

export const getRuneSetDescription = function (runeSet) {
    const descriptions = runeSetDescriptions[runeSet]
    if (descriptions) {
        return descriptions;
    } else {
        return [];
    }
}

export const PLATFORMS = ["ALL"];
export const REGIONS = ["EUROPE", "TEST"];
export const MODES = ["SOFTCORE", "HARDCORE"];
export const REALM = ["SEASON", "ETERNAL"];

export const CURRENCY_TYPES = ["Gold", rune, summoning, elixir, gem, consumable]
export const CURRENCY_NAMES = {
    "Gold": ["Million Gold"],
    "Summoning": summonings,
    "Rune": ["Any Magic Rune", "Any Rare Rune", "Any Legendary Rune",].concat(runes),
    "Rune Set": runeSets,
    "Gem": gems,
    "Elixir": elixirs,
    "Consumable": consumables
}

export const ITEM_RARITIES = ["Any", "Unique", "Legendary", "Rare", "Magic", "Normal"];
export const DEFAULT_ITEM_RARITY = ITEM_RARITIES[2];

//do not change the order since the indexes get used by this fixed order
export const CLASSES = ["Any", "Druid", "Rogue", "Necromancer", "Barbarian", "Sorcerer", "Spiritborn"];

export const ITEM_TYPES = ["Any", "Weapon", "Armor", "Jewelry", "Misc"];

export const ITEM_TYPES_WITH_RARITY = ["Any", "Weapon", "Armor", "Jewelry"]
export const ITEM_TYPES_WITH_DPS = ["Weapon"]
export const ITEM_TYPES_WITH_ARMOR = ["Armor"]

export const canHaveAspects = function (baseItem) {
    return baseItem !== undefined && baseItem != null && (baseItem.rarity === ITEM_RARITIES[0] || baseItem.rarity === ITEM_RARITIES[2]);
}

export const canHaveUniqueAffix = function (baseItem) {
    return baseItem !== undefined && baseItem != null && (baseItem.rarity === ITEM_RARITIES[0] || baseItem.rarity === ITEM_RARITIES[1]);
}

export const isLegendaryOrUnique = function (baseItem) {
    return baseItem !== undefined && baseItem != null && (baseItem.rarity === ITEM_RARITIES[1] || baseItem.rarity === ITEM_RARITIES[2]);
}

export const canHaveDps = function (baseItem, baseStats) {
    if (baseStats && baseStats.dps > 0) {
        return true;
    }

    return contains(ITEM_TYPES_WITH_DPS, baseItem.itemType) && baseItem.name !== "Shield";
}

export const canHaveArmor = function (baseItem, baseStats) {
    if (baseStats && baseStats.armor > 0) {
        return true;
    }

    return contains(ITEM_TYPES_WITH_ARMOR, baseItem.itemType) || baseItem.name === "Shield";
}

export const ITEM_SUB_TYPES = {
    "Any": ["Any"],
    "Weapon": ["Two-Handed", "One-Handed", "Off-Hand", "Bow", "Crossbow"],
    "Armor": ["Helm", "Chest", "Gloves", "Pants", "Boots"],
    "Jewelry": ["Amulet", "Ring"],
    "Misc": [summoning, rune, runeSet, gem, elixir, consumable]
}

export const isSummoningMaterial = function (item) {
    return item && item.subType === summoning;
}

export const isMagicRune = function (item) {
    return item && contains(magicRunes, item.name);
}

export const isRareRune = function (item) {
    return item && contains(rareRunes, item.name);
}

export const isLegendaryRune = function (item) {
    return item && contains(legendaryRunes, item.name);
}

export const isRune = function (item) {
    return item && item.subType === rune;
}

export const isRuneSet = function (item) {
    return item && item.subType === runeSet;
}

export const isRuneOrRuneSet = function (item) {
    return item && (item.subType === runeSet || item.subType === rune);
}

export const ITEM_SUB_TYPES_WITH_AMOUNT = [rune, runeSet, summoning, elixir, gem, consumable]
export const ITEM_SUB_TYPES_WITHOUT_STATS = [rune, runeSet, summoning, elixir, gem, consumable]

export const ITEM_NAMES = {
    "Leveling": ["Any", "1-50", "1-30", "30-50", "50-60", "60-70", "70-80"],
    "World Tier Unlock": ["Any", "T3", "T4", "T3+T4"],
    "Boss Kill": ["Any", "Uber Lilith", "Uber Malphas", "Duriel (seller mats)", "Duriel (buyer mats)", "Varshan (seller mats)", "Varshan"
    + " (buyer mats)", "Grigoire (seller mats)", "Grigoire (buyer mats)"],
    "Any": ["Any"],
    "Two-Handed": ["Any", "Axe", "Mace", "Polearm", "Staff", "Sword", "Scythe", "Quarterstaff"],
    "One-Handed": ["Any", "Axe", "Mace", "Staff", "Sword", "Scythe", "Dagger", "Wand"],
    "Bow": ["Any", "Bow"],
    "Crossbow": ["Any", "Crossbow"],
    "Off-Hand": ["Any", "Book", "Focus", "Shield", "Totem"],

    "Helm": ["Any", "Helm"],
    "Chest": ["Any", "Chest"],
    "Gloves": ["Any", "Gloves"],
    "Pants": ["Any", "Pants"],
    "Boots": ["Any", "Boots"],

    "Amulet": ["Any", "Amulet"],
    "Ring": ["Any", "Ring"],

    "Summoning": ["Any"].concat(summonings),
    "Rune": ["Any"].concat(runes),
    "Rune Set": ["Any"].concat(runeSets),
    "Gem": ["Any"].concat(gems),
    "Elixir": ["Any"].concat(elixirs),
    "Consumable": ["Any"].concat(consumables)
}

export const getCurrencyTypeByCurrency = function (currency) {
    let result;
    Object.keys(CURRENCY_NAMES).forEach(key => {
        if (containsIgnoreCase(CURRENCY_NAMES[key], currency)) {
            result = key;
        }
    });
    return result;
}

export const getItemTypesByName = function (name) {
    let subTypes = [];
    Object.keys(ITEM_NAMES).forEach(key => {
        if (containsIgnoreCase(ITEM_NAMES[key], name)) {
            subTypes.push(key)
        }
    });

    let result = [];

    subTypes.forEach(subType => {
        Object.keys(ITEM_SUB_TYPES).forEach(key => {
            if (containsIgnoreCase(ITEM_SUB_TYPES[key], subType)) {
                result.push({
                    itemType: key,
                    itemSubType: subType
                })
            }
        })
    })

    return result;
}