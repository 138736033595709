import React from "react";
import EiaButton from "../EiaButton";
import {AccountSettings, Divider, Link, useAuthenticator} from "@aws-amplify/ui-react";
import EiaDialog from "./EiaDialog";
import AlertCard from "../cards/AlertCard";
import {useCookies} from "react-cookie";
import EiaTextField from "../EiaTextField";

function AccountDialog(props) {
    const { onClose, open} = props;

    const {user} = useAuthenticator((context) => [context.user]);

    const [openChangedPassword, setOpenChangePassword] = React.useState(false)
    const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false)
    const [alert, setAlert] = React.useState(null)

    const [cookies, setCookies, removeCookies] = useCookies(['darkMode']);
    const darkMode = cookies.darkMode === undefined ? true : cookies.darkMode;
    const textColor = darkMode ? 'black' : 'white';

    const handleSuccessPasswordChanged = function () {
        setAlert(["success", ("Successfully changed password")]);
        setOpenChangePassword(false);
    }

    return (
        <EiaDialog title={"Account"} open={open} onClose={onClose} isLoading={false}>
            {alert == null ? null : <AlertCard alert={alert}/>}
            <EiaTextField
                label={"Account Name"}
                value={user ? user.username : null}
                isReadOnly
            />
            <Divider/>
            <EiaDialog title={"Change Password"} open={openChangedPassword} onClose={() => setOpenChangePassword(false)} isLoading={false}>
                <AccountSettings.ChangePassword
                    displayText={{
                        currentPasswordLabel: 'Enter current password',
                        newPasswordLabel: 'Enter new password',
                        confirmPasswordLabel: 'Confirm new password',
                        updatePasswordText: 'Update your password',
                    }}
                    onSuccess={handleSuccessPasswordChanged}
                />
            </EiaDialog>
            <EiaButton isFullWidth onClick={() => setOpenChangePassword(true)}>Change Password ...</EiaButton>
            <EiaButton isFullWidth={true}>
                <Link
                    href="mailto:easyitemtrading@gmx.de?subject=EasyItemTrading Delete Account"
                    color={textColor}
                >
                    Contact to Delete Account
                </Link>
            </EiaButton>
        </EiaDialog>
    );
}

export default AccountDialog;