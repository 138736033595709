import React from "react";
import {Flex, Heading, Label, Text, TextAreaField} from "@aws-amplify/ui-react";
import EiaImage from "./EiaImage";
import EiaCard from "./cards/EiaCard";
import Utils from "../commons/Utils/Utils";
import YoutubeVideo from "./YoutubeVideo";
import {COLOR_GRAY} from "../commons/enums/ColorEnums";

const isSmallScreen = Utils.isSmallScreen();
const imagesDirection = isSmallScreen ? "column" : "row";

const NewsEntry = function (props) {
    const {newsObject} = props;

    return (
        <EiaCard>
            <Heading level={4}>{newsObject.title}</Heading>
            <Text fontSize={"0.75em"} style={{color: COLOR_GRAY}}>{newsObject.date}</Text>
            {
                newsObject.newsV2 ? <div>
                        <EiaCard variation={"outlined"} roundCorners={true}>
                            <Flex direction={"column"} gap={"0rem"}>
                                {
                                    newsObject.newsV2.map(((news, index) => {
                                        return <Label key={index}>{news}</Label>
                                    }))
                                }
                            </Flex>
                        </EiaCard>
                    </div>
                    : null
            }
            {
                newsObject.news ? <TextAreaField descriptiveText={newsObject.date}
                                                 isReadOnly={true} defaultValue={newsObject.news} rows={newsObject.rows ? newsObject.rows : 3}>
                    </TextAreaField>
                    : null
            }
            {
                newsObject.youtubeSrc ?
                    <Flex direction={"row"} justifyContent={"center"}>
                        <EiaCard>
                            <YoutubeVideo src={newsObject.youtubeSrc}></YoutubeVideo>
                        </EiaCard>
                    </Flex> : null
            }
            {
                newsObject.images ?
                    <Flex direction={imagesDirection} justifyContent={"center"}>
                        {
                            newsObject.images.map((image, index) => {
                                return (<EiaCard key={index}>
                                    <EiaImage objectFit="cover" height={400} src={image} tealBorder={true}/>
                                </EiaCard>)
                            })
                        }
                    </Flex>
                    : null
            }
            {
                newsObject.image ?
                    <Flex direction={"row"} justifyContent={"center"}>
                        <EiaCard>
                            <EiaImage objectFit="cover" height={400} src={newsObject.image} tealBorder={true}/>
                        </EiaCard>
                    </Flex>
                    : null
            }
        </EiaCard>
    )
}
export default NewsEntry;